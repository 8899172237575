@import "~@/styles/base/variable";

.homepage-header {
    position: absolute;
    width: 100%;
    z-index: $zindex-header;
    background: rgba(0, 0, 0, 0.23);
    top: 0;

    &-logo {
        background: url('../../images/logo-btc-white.png') no-repeat center center;
        background-size: 130px;
        float: left;
        margin-bottom: 0;

        a {
            width: 130px;
            height: 22px;
            text-indent: -999em;
            display: block;
        }
    }

    &-content {
        width: $layout-width;
        margin: 0 auto;
        height: 40px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 1.142857rem
            /* 16/14 */
        ;

        &-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            font-size: 16px;

            .header-login-container {

                .header-content__user,
                .header-content__login-btns {

                    button,
                    a {
                        background-color: transparent;
                        border: 0;
                        margin-left: 40px;
                        color: #fff;

                        &:hover,
                        &:active,
                        &:focus {
                            opacity: .7;
                            cursor: pointer;
                        }
                    }
                }

                .header-content__user {
                    .ant-dropdown-link {
                        padding-right: 0;
                    }
                }

            }

            .header-right-user {
                background-color: transparent;
                padding: 0;
            }

            .header-right-setting {
                padding: 0;
                margin-left: 20px;
            }
        }
    }

    &-nav {
        margin-left: 30px;
        color: #fff;
        display: inherit;
        position: relative;

        &-item {
            &:hover {
                opacity: .7;
            }

            &-active {
                a {
                    border-radius: 3px;
                    background-color: #508ad3;
                    text-decoration: none;
                }
            }

            .red-point {
                position: absolute;
                right: -5px;
                width: 7px;
                top: 3px;
                background-color: #ef4444;
                height: 6px;
                border-radius: 8px;
            }
        }

        a {
            font-size: 16px;
            padding: 0 15px;
            height: 38px;
            line-height: 38px;
            color: #fff;
            display: block;
            font-weight: 330;

            &:hover {
                color: #fff;
            }
        }
    }

    &-service {
        position: absolute;
        top: 54px;
        background-color: #fff;
        width: 180px;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 6px;
        -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        padding: 10px;

        &-row {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 11px;
            border-bottom-color: rgba(0, 0, 0, .25);
            top: -21px;
            left: 84px;

            &:after {
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                border-width: 10px;
                content: "";
                top: 1px;
                margin-left: -10px;
                border-top-width: 0;
                border-bottom-color: #fff;
            }
        }

        &-content {
            width: 160px;
            height: 154px;
            background: url('../../images/service.png') 50% 50% no-repeat;
            background-size: 165px auto;
        }
    }
}

.header-content__user-dropdown {
    display: flex;
    background: #fff;
    border-radius: 4px;

    .header-content__user-dropdown__avatar {
        width: 58px;
        height: 58px;
        background: url('~@/images/homepage/avator.png') center center no-repeat;
        background-size: 25px auto;
        border-right: 1px solid rgba(0, 0, 0, 0.25);

        img {
            width: 100%;
            height: 100%;
        }
    }

    .header-content__user-dropdown__link-user {
        flex: 1 1 auto;

        .ant-menu-item {
            height: 21px;
            line-height: 21px;

            .ant-menu {
                border-radius: 4px;
            }
        }
    }
}

.login-modal.ant-modal {
    width: 360px;
    height: 580px;

    .login-frame {
        width: 360px;
        height: 580px;

        &>iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    .ant-modal-header {
        width: 100%;
        background: transparent;
    }

    .ant-modal-body {
        padding: 0;
    }
}

.overlay-container {
    position: relative;

    .overlay-content {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}