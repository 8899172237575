@import "~@/styles/base/variable";

.user-list{
    .user-list__card{
        margin-bottom: 8px;
        .ant-card-head{
            background:$card-header-bg;
        }
    }
}
 