@import "../../../styles/base/variable.scss";

.ant-modal-header {
    color: $text-color-primary;
    font-size: $font-size-lg;
    height: $modal-header-height;
    vertical-align: middle;
    background-color: $card-header-bg;
    padding-top: $padding-md;
}

.ant-modal-footer {
    height: $modal-footer-height;

    .ant-btn:not(.ant-btn-primary) {
        color: $text-color-default;
    }
}