@import "../../../styles/base/variable.scss";
.btc-card {
  background: #fff;
  border-radius: $border-radius-base;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  &-header {
    display: flex;
    background: $card-header-bg;
    height: $card-header-height;
    padding-left:$card-padding;
    padding-right:$card-padding;
    .btc-card-title {
      flex: 1;
      font-size: $font-size-lg;
      font-weight: 500;
      color:rgba(0,0,0,0.85);
      line-height: $card-header-height;
     
    }
    .btc-card-extra {
      float: right;
      font-size: 14px;
      line-height: $card-header-height;
    }

   
  }

  &.btc-card-ghost{
    border:1px solid $line-color;
      .btc-card-header{
        background: #fff;
        border-bottom: 1px solid $line-color;
      }
  }

  &-body{
    padding:$card-padding-lg $card-padding;
  }

  &.btc-card-sm{
    .btc-card-header {
      height: $card-header-height-sm;
      .btc-card-title {
        font-size: $font-size-base;
        line-height: $card-header-height-sm;
      }
      .btc-card-extra {
        line-height:  $card-header-height-sm;
      }
    }
  
    .btc-card-body{
      padding:$card-padding-lg $card-padding;
    }
  }
  

}
.card-form-item{
  font-size: 14px;
  display: flex;
  margin-top:10px;
  &:first-child{
    margin-top:0;
  }
 
  dt{
    text-align: left;
  }
  dd{
    flex: 1;
    margin:0;
    .suffix{
      margin-left: 4px;
    }
  }

  &.larget-text{
      font-size: 16px
   
  }

}