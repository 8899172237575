
.pagination{
  text-align: center;
  position: relative;

  .simple-total{
    position: absolute;
    z-index: 99;
    left: 58%;
    top: 50%;
    margin-top: -11px;
    font-size: 14px;
  }
  
}

.align-right{
  text-align: right; 
}
.align-left{
  text-align: left; 
}
.align-center{
  text-align: center; 
}
