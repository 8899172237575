@import "../../../styles/base/variable.scss";

.group-selector-container{
  ul{
    li{
      display: inline-block;
      margin-right: 20px;
     
     
      a{
        padding:4px 8px;
        text-decoration: none;
        color:rgba(0, 0, 0, 0.65);
        border-radius: $border-radius-base;
       
        &:hover{
          color: $link-hover-color;
        }
      }
      &.active{
        a{
          background-color: $primary-color;
          color: #fff;

        }
      }
     
    }
  }
}