@import "./variable.scss";
$active-color:#2EA8ED;

body {

    .ant-carousel {
        .slick-dots {
            height: 8px;

            li {
                background-color: #ccc;
                width: 8px;
                height: 8px;
                border-radius: 100%;

                button {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    opacity: 0;
                }

                &.slick-active {
                    background-color: $active-color;
                }

                &.slick-active button {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: $active-color;
                }

                &:hover {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: $active-color;
                }

            }
        }
    }

    // ant-table style reset
    .ant-table-thead>tr>th {
        background-color: #fff;
        font-weight: 400;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        padding: 10px 12px;

        &.ant-table-selection-column {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .ant-table-wrapper {
        .ant-table-thead>tr>th.ant-table-column-has-sorters {
            background: #fff;
        }

        .ant-table-tbody>tr>td.ant-table-column-sort {
            background: #fff;

        }

        .ant-table-tbody>tr:hover>td.ant-table-column-sort {
            background: #F3F4F7
        }

        .ant-table-thead>tr>th.ant-table-column-has-sorters:hover,
        .ant-table-thead>tr>th .ant-table-column-sorters:hover::before {
            background: #fff;
        }

        .ant-table-tbody>tr.ant-table-row-selected td {
            background: #fafafa !important;
        }
    }

    .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters,
    .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters {
        padding-right: 18px !important;
    }


    // pagination 
    $page-item-height:24px;

    %page-item {
        min-width: $page-item-height;
        height: $page-item-height;
        line-height: $page-item-height;
        border: none;
        margin-right: 6px;
    }

    .ant-pagination-item {
        @extend %page-item;

        &.ant-pagination-item-active {
            background-color: $secondary-color;

            a {
                color: #fff;
            }
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        @extend %page-item;

        .ant-pagination-item-link {
            border: none;
        }
    }

    .ant-pagination-total-text {
        height: $page-item-height+2px;
        line-height: $page-item-height+2px;
    }

    .ant-pagination {
        .ant-select-selection--single {
            height: $page-item-height+2px;
        }

        .ant-select-selection__rendered {
            line-height: $page-item-height+2px;
        }
    }

    .ant-pagination-options-quick-jumper {
        height: $page-item-height+2px;
        ;
        line-height: $page-item-height+2px;

        input {
            height: $page-item-height+2px;
            padding: 2px 10px;
        }
    }

    // ant icon
    .anticon {
        user-select: none;
    }

    // ant modal
    .modal-hide-cancel {
        .ant-modal-footer {
            .ant-btn:not(.ant-btn-primary) {
                display: none;
            }
        }
    }

    .modal-hide-ok {
        .ant-modal-footer {
            .ant-btn-primary {
                display: none;
            }
        }
    }

    // ant dropdown
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background: #f3f4f7;
    }

    // ant select menu
    .ant-select-dropdown-menu-item:hover,
    .ant-select-dropdown-menu-item-active {
        background-color: $list-hover-bg;
    }

    // tooltip
    .ant-tooltip.auto-tooltip-width {
        max-width: none;
    }

    // card
    .ant-card {
        border-radius: $border-radius-base;

        .ant-card-head {
            border-radius: $border-radius-base $border-radius-base 0 0;
        }
    }

}