.header-right-user {
    padding: 11px 6px;
    background-color: #508ad3;
    border-radius: 4px;
    font-weight: 300;
    display: flex;
    align-items: center;
    cursor: pointer;

    .anticon {
        margin-left: 10px;
    }
}

.header__user-dropdown {
    z-index: 999;
}

.header__user-content {
    width: 600px;
    background: rgba(230, 233, 238, 1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    padding: 24px 16px 16px 16px;

    .user-content__title,
    .user-content__help-tip,
    .user-content__btns {
        margin-bottom: 16px;
    }

    .user-content__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user-content__btns-left {
            display: flex;
            justify-content: start;
            align-items: center;

            .user-content__btns-group {
                margin-left: 24px;

                .ant-btn {
                    margin-right: 16px;
                }
            }
        }
    }

    .user-content__user-list {
        max-height: 500px;
        overflow-y: auto;
    }
}