@import "./variable";

// åºæ¬ Tag
html,
body {
    font-size: 14px;
    width: 100%;
    height: 100%;
    background-color: $page-color;
    min-width: 1180px;

    #root {
        width: 100%;
        height: 100%;
    }
}

h1 {
    font-size: $font-size-xlg;
}

h2 {
    font-size: $font-size-lg;
}

h3 {
    font-size: $font-size-base;
}

a {
    text-decoration: none;
}

input:invalid {
    box-shadow: none;
}

// è¶é¾æ¥
.link,
.btn-link,
.link a {

    &,
    &:visited {
        color: $link-color;
        cursor: pointer;
    }

    &.link-white {
        color: #fff;

        &:hover {
            color: darken(#fff, 10%);
            text-decoration: underline;
        }
    }

    &:hover {
        color: lighten($link-color, 10%);
        text-decoration: underline;
    }
}


.btn-link {
    border: 0;
    background: inherit;
}

.pure-link {
    color: $link-color;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;

    &:hover {
        color: lighten($link-color, 10%);
    }
}

// Margin å¤§å°
.margin-top-xlg {
    margin-top: $dimension-xlg;
}

.margin-top-lg {
    margin-top: $dimension-lg;
}

.margin-top-md {
    margin-top: $dimension-md;
}

.margin-top-sm {
    margin-top: $dimension-sm;
}

.margin-bottom-lg {
    margin-bottom: $dimension-lg;
}

.margin-bottom-md {
    margin-bottom: $dimension-md;
}

.margin-bottom-sm {
    margin-bottom: $dimension-sm;
}

.margin-left-xlg {
    margin-left: $dimension-lg;
}

.margin-left-lg {
    margin-left: $dimension-lg;
}

.margin-left-md {
    margin-left: $dimension-md;
}

.margin-left-sm {
    margin-left: $dimension-sm;
}

.margin-right-xlg {
    margin-right: $dimension-lg;
}

.margin-right-lg {
    margin-right: $dimension-lg;
}

.margin-right-md {
    margin-right: $dimension-md;
}

.margin-right-sm {
    margin-right: $dimension-sm;
}

// width ã height 100%
.height100 {
    height: 100%;
}

.width100 {
    width: 100%;
}

// Text Align
.text-center {
    text-align: center;
}

//éç®ææ¬
.success-text {
    color: $success-color;
}

.warning-text {
    color: $warning-color;
}

.error-text {
    color: $error-color;
}

.important-text {
    color: $important-color;
}

.increase-text {
    color: $increase-color;
}

.decline-text {
    color: $decline-color;
}

.disable-text {
    color: $disable-color;
}


//å¿è¾é¡¹
.required-text {
    color: $error-color;
}

.required-tip {
    font-size: 12px;
}

//æ¬¡éè¦ææ¬ å¦ï¼è¡¨åæ ç­¾ãåä½
.secondary-text {
    color: $text-color-secondary;
}

// ä¸éè¦çå¤æ³¨ãæéç­
.minor-text {
    color: $text-color-secondary;
    font-size: 13px;
}


// .remark{
//   font-size: 12px;
//   color:$text-color-secondary;
// }

// æéãè­¦ç¤ºåå®¹
.caution {
    border: 1px solid lighten(#eca516, 5%);
    padding: $padding-sm;
    font-size: $font-size-sm;
    background-color: rgba(252, 210, 126, 0.1);
    color: #eca516;

    &.show-icon {
        padding-left: 30px;
        background: url(../../images/V2/icon_tips_normal.svg) left 8px top 8px no-repeat;
        background-size: 15px 15px;
        background-color: rgba(252, 210, 126, 0.1);
    }

}


// åºå®æå¤§å®½åº¦ Layout
.view-width {
    max-width: $layout-width;
    width: $layout-width;
    margin: 0 auto;
}

// æå¤§å®½åº¦
.container-fluid {
    width: 100%;
    margin: 0 auto;
}


// ç­å®½å­ä½
.monospace {
    font-family: Monaco, 'Droid Sans Mono', Menlo, Consolas, 'Courier New',
        monospace !important;
}


//åå²çº¿
.line {
    width: 100%;
    height: 1px;
    background-color: $line-color;
    margin: $margin-md 0;
}

// ç¸å¯¹å®ä½
.relative {
    position: relative;
}

//#region remove left/right padding
.nopadding-sides {
    padding-left: 0;
    padding-right: 0;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    &.smpadding-sides {
        padding-left: 6px;
        padding-right: 6px;
    }

    &.nopadding-sides {
        padding-left: 0;
        padding-right: 0;
    }

    &.nopadding-left {
        padding-left: 0;
    }

    &.nopadding-right {
        padding-right: 0;
    }
}

.nopadding-left {
    padding-left: 0;
}

.nopadding-right {
    padding-right: 0;
}

// æå­é´çå·¦å³é´è·
.gap {
    margin-left: 4px;
    margin-right: 4px;
}

.gap-r {
    margin-right: 4px;
}

.gap-l {
    margin-left: 4px;
}

//#endregion

// æå­æ¯å¦æè¡
// ä¸æè¡
.nowrap {
    white-space: nowrap;
}

// æè¡
.word-break {
    word-break: break-all;
    word-wrap: break-word;
}

// å­ä½å¤§å°
.font-lg {
    font-size: $font-size-lg
}

.font-xlg {
    font-size: $font-size-xlg
}

// é¿åº¦ç¼©ç¥
.cell-text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
}


// éè
.hide {
    display: none;
}
.show {
    display: block;
}

.invisible{
    visibility: hidden;
}

.visible{
    visibility: visible;
}



//#region icon
@mixin iconFrg($size, $bgUrl, $bgX, $bgY) {
    display: inline-block;
    width: $size;
    height: $size;
    vertical-align: middle;
    background: url($bgUrl) no-repeat $bgX $bgY;
}

.coin-logo {
    width: 24px;
    height: 24px;
    margin-right: $margin-sm;
    vertical-align: -6px;
}

i {
    &.icon-info {
        display: inline-block;
        width: $icon-size;
        height: $icon-size;
        vertical-align: -2px;
        background: url('../../images/instruction@2x.png') no-repeat left center;
        background-size: 16px 16px;

        &:hover {
            background: url('../../images/instruction_hover@2x.png') no-repeat left center;
            background-size: 16px 16px;
        }
    }

    &.icon-hashrate {
        @include iconFrg($icon-size-xlg, '../../images/V2/sprite_banner_icon.png', 0, -2px);
        background-size: 48px 192px;
    }

    &.icon-runningstatus {
        @include iconFrg($icon-size-xlg, '../../images/V2/sprite_banner_icon.png', 0, -146px);
        background-size: 48px 192px;
    }

    &.icon-miners {
        @include iconFrg($icon-size-xlg, '../../images/V2/sprite_banner_icon.png', 0, -50px);
        background-size: 48px 192px;
    }

    &.icon-earnings {
        @include iconFrg($icon-size-xlg, '../../images/V2/sprite_banner_icon.png', 0, -98px);
        background-size: 48px 192px;
    }

    &.icon-linkout {
        @include iconFrg($icon-size, '../../images/V2/link_out.png', 0, 0);
        background-size: 16px 14px;
    }

    &.icon-copy {
        @include iconFrg($icon-size, '../../images/V2/icon_copy_normal@2x.png', 0, 0);
        background-size: 16px 16px;
    }

    &.icon-manage {
        @include iconFrg($icon-size, '../../images/V2/icon_manage@2x.png', 0, 0);
        background-size: 16px 16px;
    }

    &.icon-success {
        @include iconFrg($icon-size, '../../images/V2/icon_successful.svg', 0, 0);
        background-size: 16px 16px;
        margin-right: $margin-sm;
        vertical-align: -3px;
    }
}

//#endregion 