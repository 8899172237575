@import "../../styles/base/variable";

.header {
    background: #3c78c2;
    position: relative;
    z-index: $zindex-header;

    &-logo {
        background: url('https://s.cloverpool.com/public/images/cloverpool_logo_white.png') no-repeat center center;
        background-size: 150px;
        float: left;
        margin-bottom: 0;

        a {
            width: 150px;
            height: 30px;
            text-indent: -999em;
            display: block;
        }
    }

    &-content {
        width: $layout-width;
        margin: 0 auto;
        height: 60px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;

        &-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;


        }
    }

    &-nav {
        margin-left: 30px;
        color: #fff;
        display: inherit;
        position: relative;

        &-item {
            &-active {
                a {
                    border-radius: 3px;
                    background-color: #508ad3;
                    text-decoration: none;
                }
            }

            .red-point {
                position: absolute;
                right: -5px;
                width: 7px;
                top: 3px;
                background-color: #ef4444;
                height: 6px;
                border-radius: 8px;
            }
        }

        a {
            font-size: 16px;
            padding: 0 18px;
            height: 38px;
            line-height: 38px;
            color: #fff;
            display: block;
            font-weight: 330;

            &:hover {
                color: #fff;
            }
        }
    }


    &-right-setting {
        padding: 11px 10px;
        display: inline;
        cursor: pointer;
        border-radius: 4px;
        margin-left: 10px;
        font-weight: 330;
    }

    .lang {
        width: 92px;
        padding: 0 8px;
        line-height: 30px;
        border: solid 1px #fff;
        border-radius: 4px;
        margin-left: 25px;
        text-align: center;
        position: relative;
        cursor: pointer;
        float: right;

        &-title {
            color: #fff;
        }

        .caret {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 2px;
            vertical-align: middle;
            border-top: 6px dashed;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
        }

        .dropdown-menu {
        border-radius: 4px;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: block;
        float: left;
        min-width: 92px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        color: #333;

        li:hover {
            text-decoration: none;
            color: #262626;
            background-color: #f5f5f5;
        }

        li.disabled,
        li.disabled:hover {
            text-decoration: none;
            background-color: transparent;
            background-image: none;
            cursor: not-allowed;
            color: #777;
        }
    }
    }
}