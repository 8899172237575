@import "~@/styles/base/variable";

.user-list__inner {
    margin-bottom: $margin-lg;

    &:last-child {
        margin-bottom: 0;
    }

    .user-list__coin-action {
        display: flex;
        align-items: center;

        .user-list__coin-switch {
            margin-left: $margin-md;

            &>span:first-child {
                margin-right: $margin-md;
            }

            .ant-btn {
                margin-right: $margin-sm;
                background: $chunk-color;
                border-radius: $border-radius-base;

                &.ant-btn-primary {
                    color: #fff;
                    background-color: $primary-color;
                }
            }
        }
    }

    .user-list__coin-list {
        border: 1px solid $page-color;

        .ant-list-item {

            &:hover,
            &:active {
                background: $list-hover-bg;
            }

            .user-list__coin-item {
                display: flex;
                justify-content: space-between;
                // padding: 4px 24px 4px 14px;
                align-items: center;
                cursor: pointer;
                width: 100%;

                &>div {
                    // flex: 1 1 auto;
                    flex: 1 1 0;
                    text-align: center;
                }

                .user-list__coin-name {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 100px;

                    .current-mining {
                        background-color: #7bb855;
                        width: 5px;
                        height: 5px;
                        border-radius: 100%;
                        margin-right: 5px;

                        &~img {
                            margin-left: 0;
                        }
                    }

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 5px;
                        margin-left: 10px;
                    }

                    &>span {
                        text-align: center;
                    }
                }

                .user-list__hashrate {
                    padding-right: 10%;

                    text-align: right;
                }
            }
        }

    }

    .ant-list-footer {
        padding: 0 !important;
        background-color: lighten($color: $card-header-bg, $amount: 2);

        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
            background-color: lighten($color: $card-header-bg, $amount: 1);
        }

        .user-list__footer {
            text-align: center;
        }
    }
}