@import "~@/styles/base/variable";

$footer-height: 360px;

.homepage-content {
    min-height: calc(100% - #{$footer-height}); // make sure footer is in the bottom of the page, and the content is full page without header/footer
    color: $text-color-default;
    background-color: #fff;
    padding-bottom: 1px;
}

// é¡µé¢ Layout
$height: calc(100% - 60px - #{$footer-height} - #{3 * $margin-sm + 8px});

.page-content {
    background-color: $page-color;
    width: $layout-width;
    margin: $margin-md auto;
    min-height: $height; // make sure footer is in the bottom of the page, and the content is full page without header/footer
    color: $text-color-default;
    display: flex;
    justify-content: center;

    &>div {
        height: 100%;
    }

    &.full-page-content {
        padding: 0;
        margin: 0 auto;
        width: 100%;
        min-height: calc(#{$height} + #{3 * $margin-sm + 8px});
    }
}

.fade-enter {
    opacity: 0;
    z-index: 1;

    &.fade-enter-active {
        opacity: 1;
        transition: opacity 250ms ease-in;
    }
}


.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}


.fade-appear {
    opacity: 0.01;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}