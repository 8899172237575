.svg-icon {
  fill: currentColor;
  overflow: hidden;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  &.xs {
    font-size: 14px;
  }
  &.sm {
    font-size: 16px;
  }
  &.md {
    font-size: 24px;
  }
  &.lg {
    font-size: 32px;
  }
  &.xlg {
    font-size: 48px;
  }
  &.clickable {
    cursor: pointer;
    &:hover {
      color: #7799BB;
    }
  }
}