@import "../../../styles/base/variable.scss";

.banner-card{
  padding:24px 12px;
  background-color: #fff;
  border-radius: $border-radius-base;

  &-header{
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    height: 56px;
    border-bottom: 1px solid #e6e9ee;
  }
  &-title{
    color:$text-color-primary;
  }
  &-body{
    display:flex;
    margin-top: 24px;
    .banner-card-item{
      text-align: center;
      flex:1;
      h2{
        color:$text-color-secondary;
      }
    }
  }

  &.banner-card-link{
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover{
      box-shadow:0px 2px 4px 1px rgba(0,0,0,0.2);

    }
  }
}

.card-item-text{
  font-size: $font-size-xlg;
}
.card-item-unit{
  font-size: $font-size-sm;
  color:$text-color-secondary;
  margin-left: $margin-sm;
}
