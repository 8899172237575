@import "~@/styles/base/variable";

.header-right-setting {
    .ant-dropdown-link {
        color: #FFFFFF;

        &:focus {
            text-decoration: none;
        }

        .anticon {
            display: none;
        }
    }
}

.setting-dropdown-container {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
    border-radius: 4px;

    .ant-menu {
        border-radius: 4px;
    }

    // ant menu reset
    .ant-menu {
        border-right: 0;

        &>.ant-menu-item,
        &>.ant-menu-item-selected {
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: #fff;
            color: $text-color-default;
            margin-bottom: 0;

            &>a {
                color: $text-color-default;

            }

            &:hover {
                background-color: $list-hover-bg;

                &>a {
                    color: $text-color-default;

                }
            }
        }
    }

    .ant-menu-inline .ant-menu-item {
        margin: 0;
        width: 100%;

        &:after {
            transition: none;

        }
    }

    .setting-content {
        background-color: #fff;
        border-radius: 4px;
        min-width: 315px;

        .address-info {
            padding: $padding-sm $padding-md 0 $padding-md;
            cursor: default;

            p {
                font-size: 12px;
                line-height: 22px;
            }

        }

    }

    // å¨å­è´¦æ·ç®¡çé¡µé¢éæ°è®¾ç½®å¤§å°
    &.manage-account-setting {
        .setting-content {
            min-width: auto;
        }
    }

}