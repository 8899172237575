@import '../../../styles/base/variable.scss';
.btc-button-container{
  display: inline-block;
  &:not(:first-child){
    margin-left: 10px;
  }
  .ant-btn-sm{
    min-width: 64px;
  }
  .ant-btn:hover{
    .svg-icon{
      color:#ff0;
    }
  }
}