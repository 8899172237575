@import "../../styles/base/variable";

.position-relative {
    position: relative;
}

.header-service {
    margin:0 auto;
    background-color: #fff;
    width: 180px;
    padding: 10px;
   
    &-content {
        width: 160px;
        height: 154px;
        background: url('../../images/service.png') 50% 50% no-repeat;
        background-size: 165px auto;
    }
}