@import "../../../../styles/base/variable.scss";

.grin-switch-container{
  margin:0 $margin-sm 0 2px;
  background-color: #2d5a91;
  border-radius: 4px;

  .ant-btn{
    height: 26px;
    font-size:$font-size-sm;
    background-color: #2d5a91;
    border: none;
    color: rgba(230,233,238,.7);
    padding:0 8px;

    &.on{
      background-color: #fffaa2;
      color:$text-color-primary;
      border-radius: 4px;
      transform: scale(0.98)
    }

  }
 
}