@import "../../../styles/base/variable";
.breadcrumbbar{
  width:100%;
  height: 32px;
  //background: #fff;
  margin-bottom: 20px;
  line-height: 60px;
  border-bottom: 1px solid #E8E8E8;

  .breadcrumbbar-title{
    float: left;
    font-size:20px;
    font-weight:500;
    color:rgba(0,0,0,0.85);
  }

  .ant-breadcrumb-link {
    color:#262626;
    a{
      color:$link-color;

    }
  }

  .breadcrumb{
    float: left;
    padding-top: 0;
  }

}